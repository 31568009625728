jQuery(document).ready(function(){
  /*
  jQuery('.state-accordion > li > a').click(function(){
    var factoid = jQuery(this).next();
    if (jQuery(this).hasClass('expand')) {
      jQuery(this).removeClass('expand');
      factoid.height('');
    }
    else {
      collapseAll();
      jQuery(this).addClass('expand');
      var height = factoid.find('.factoids').outerHeight();
      factoid.height(height);
    }
  });

  function collapseAll() {
    jQuery('.expand').each(function(e){
      jQuery(this).next().height('');
      jQuery(this).removeClass('expand');
    });
  }
    */
});